<template>
  <div class="StoreConfig">
      <div class="info">
        <h1>仓库管理</h1>
        <a-row class="config">
            <a-col :span="12">
                <div class="left">
                    <label>仓库</label>
                    <a-input 
                      placeholder="请输入仓库名称/编码"
                      v-model="filter" 
                    />
                    <a-button type="primary" @click="search">查询</a-button>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="right">
                    <a-button type="primary" @click="showStore">新增仓库</a-button>
                </div>
            </a-col>
        </a-row>
        <a-table
          :columns="columns"
          :data-source="data"
          :expanded-row-keys.sync="expandedRowKeys"
        >
          <span slot="action">
            <a @click="showPosition">新增仓位</a>
          </span>
        </a-table>
      </div>
    <!-- 新增仓位 -->
    <a-modal v-model="storeposition" title="新增仓位" @ok="handleposition" width="30%">
        <div class="storehouse">
            <div class="list">
                <label>父级仓库/仓位：</label>
                <a-input placeholder="请输入" disabled/>
            </div>
            <div class="list">
                <label><i>*</i>仓库名称：</label>
                <a-input placeholder="请输入" />
            </div>
            <div class="list">
                <label><i>*</i>仓库编码：</label>
                <a-input placeholder="请输入" />
            </div>
            <div class="list">
                <label>位置：</label>
                <a-cascader :options="options" placeholder="请选择省、市、区" />
            </div>
            <div class="list">
                <label>备注：</label>
                <a-textarea placeholder=""/>
            </div>
        </div>
    </a-modal>

    <!-- 新增仓库 -->
    <a-modal v-model="storehouse" title="新增仓库" @ok="handleStore" width="30%">
        <div class="storehouse">
            <div class="list">
                <label><i>*</i>仓库名称：</label>
                <a-input placeholder="请输入" />
            </div>
            <div class="list">
                <label><i>*</i>仓库编码：</label>
                <a-input placeholder="请输入" />
            </div>
            <div class="list">
                <label>位置：</label>
                <a-cascader :options="options" placeholder="请选择省、市、区" />
            </div>
            <div class="list">
                <label>备注：</label>
                <a-textarea placeholder=""/>
            </div>
        </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getWarehouseList,
}from "@/api/warehouseManage.js"

const columns = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '编码',
    dataIndex: 'code',
  },
  {
    title: '位置',
    dataIndex: 'detailAddress',
  },
  {
    title: '备注',
    dataIndex: 'remarks',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

// const data = [
//   {
//     key: 1,
//     name: 'John Brown sr.',
//     code: 'DAGDA',
//     address: '广东省深圳市宝安区福永街道福海信息港A2栋21',
//     remark:'',
//     children: [
//       {
//         key: 11,
//         name: 'John Brown',
//         code: 42,
//         address: 'New York No. 2 Lake Park',
//         remark:'',
//       },
//       {
//         key: 12,
//         name: 'John Brown jr.',
//         code: 30,
//         address: 'New York No. 3 Lake Park',
//         remark:'',
//         children: [
//           {
//             key: 121,
//             name: 'Jimmy Brown',
//             code: 16,
//             address: 'New York No. 3 Lake Park',
//             remark:'',
//           },
//         ],
//       },
//       {
//         key: 13,
//         name: 'Jim Green sr.',
//         code: 72,
//         address: 'London No. 1 Lake Park',
//         remark:'',
//         children: [
//           {
//             key: 131,
//             name: 'Jim Green',
//             code: 42,
//             address: 'London No. 2 Lake Park',
//             remark:'',
//             children: [
//               {
//                 key: 1311,
//                 name: 'Jim Green jr.',
//                 code: 25,
//                 address: 'London No. 3 Lake Park',
//                 remark:'',
//               },
//               {
//                 key: 1312,
//                 name: 'Jimmy Green sr.',
//                 code: 18,
//                 address: 'London No. 4 Lake Park',
//                 remark:'',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     key: 2,
//     name: 'Joe Black',
//     age: 32,
//     address: 'Sidney No. 1 Lake Park',
//   },
// ];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

export default {
  data() {
    return {
      filter:'',
      pagination:{
        pageSize:10,
        total:0,
        current:1,
        skipCount:0
      },
      data:[],


      storehouse: false,
      storeposition:false,
      options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                },
              ],
            },
          ],
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          children: [
            {
              value: 'nanjing',
              label: 'Nanjing',
              children: [
                {
                  value: 'zhonghuamen',
                  label: 'Zhong Hua Men',
                },
              ],
            },
          ],
        },
      ],      
      columns,
      rowSelection,
      expandedRowKeys: [],
    };
  },
  created(){
    this.getData();
  },
  methods: {
    getData(){
      let pageSize=this.pagination.pageSize;
      let skipCount=this.pagination.skipCount;

      let param={
        filter:this.filter,
        skipCount:skipCount,
        maxResultCount:pageSize,
      };

      getWarehouseList(param).then((res)=>{
        console.log("getWarehouseList",res);
        this.data=res.items;
        this.pagination.total=res.totalCount;
        this.skipCount=param.skipCount;
      });
    },

    search(){
      this.getData();
    },
    // 新增仓位 
    showPosition() {
        this.storeposition = true;
    },
    handleposition(e) {
        this.storeposition = false;
    },  
    // 新增仓库  
    showStore() {
        this.storehouse = true;
    },
    handleStore(e) {
        this.storehouse = false;
    },
    
  },
};
</script>

<style lang="scss" scoped>
  .StoreConfig{
    padding: 30px;

    .info{
      background-color: #ffffff;
      padding: 30px;

      h1{
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;
      }
      
      .config{
          margin: 30px 0;

          .left{
              
              label{
                  display: inline-block;
                  margin-right: 15px;
              }

              .ant-input{
                  width: 70%;
                  margin-right: 30px;
              }
          }

          .right{
              text-align: right;
          }
      }

    }
  }

  .storehouse{
        padding: 20px 30px;

        .list{
            margin-bottom: 20px;

            label{
                display: inline-block;
                margin-right: 5px;
                font-size: 14px;
                width: 110px;
                text-align: right;

                i{
                    color: #d9001b;
                }
            }

            .ant-select{
              width: 70%;
            }
            .ant-input{
              width: 70%;
            }

            .ant-cascader-picker{
                width: 70%;
            }
        }
    }
</style>
